import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../components/Header";
import Container from "@material-ui/core/Container";

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#efefef",
      height: "100%",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: "none",
  },
}));

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Container className={classes.root}>
        <Grid container spacing={1} className={classes.root}>
          <Grid item xs={12}>
            {edges
              .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
              .map((edge) => {
                return (
                  <Paper
                    square
                    variant={"outlined"}
                    elevation={0}
                    className={classes.paper}
                    key={edge.node.id}
                    post={edge.node}
                  >
                    <Link to={edge.node.frontmatter.slug}>
                      {edge?.node?.frontmatter?.title}
                    </Link>
                  </Paper>
                );
              })}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`;
